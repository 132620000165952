import { useEffect } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Layout } from "@components/Layout/index";
import { getAllDocumentsByType, getHub, getModalSettings, getGlobalConfig } from "@lib/api/calls";
import { Render } from "@components/sections";
import { trackEvent } from "@lib/track-event";
import * as redisService from "@services/redis";

export default function Hub({
  config,
  preview,
  title,
  seo,
  content,
  slug,
  subscribed,
  modal,
  allProducts,
}) {
  const hasEmailCapture = content?.some((section) => section._type === "emailCapture");

  useEffect(() => {
    trackEvent("view_page", {
      pageTitle: title,
      pageCategory: "Landing Page",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
    });
  }, []);

  return (
    <Layout
      config={config}
      seo={seo}
      title={title}
      subscribed={subscribed}
      isPreview={preview}
      pageFound={slug !== null}
      modal={modal}
    >
      <main>
        {content && (
          <Render
            sections={content}
            disclaimer={hasEmailCapture ? modal.disclaimerCopy : null}
            allProducts={allProducts}
          />
        )}
      </main>
    </Layout>
  );
}

export async function getStaticPaths() {
  // Return a list of possible value for id
  const allHubs = await getAllDocumentsByType("hub");
  const paths = allHubs?.map((hub) => ({
    params: {
      slug: hub.slug,
    },
  }));

  return {
    paths,
    fallback: true,
  };
}

export async function getStaticProps({ params, preview = false, locale }) {
  const modal = await getModalSettings(locale, preview);
  const config = await getGlobalConfig(locale, preview);
  const hub = params?.slug ? await getHub(params.slug, locale, preview) : null;

  let allProducts = [];

  try {
    allProducts = await redisService.getProductList(hub?.blocks);
  } catch (error) {
    allProducts = [];
    console.error(error.stack || error);
  }

  const seo = {
    seoTitle: hub?.meta?.seoTitle || `Kotn · ${hub?.title}`,
    seoDescription: hub?.meta?.seoDescription || "",
    openGraphImage: hub?.meta?.openGraphImage || null,
    twitterCard: hub?.meta?.seoTwitterCardStyle || "summary_large_image",
  };

  return {
    props: {
      preview,
      title: hub?.title || null,
      seo,
      content: hub?.blocks || null,
      slug: hub?.slug || null,
      modal,
      config,
      allProducts: allProducts,
      ...(await serverSideTranslations(locale, [
        "common",
        "navbar",
        "footer",
        "productcard",
        "cart",
        "recommendations",
      ])),
    },
    revalidate: 60,
  };
}
